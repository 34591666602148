import { graphql } from 'gatsby';
import { ArticleMeta } from "../../../../src/common/ArticleMeta";
import { SEO } from "../../../../src/common/seo";
import One from "../../../../src/articles/self-updating-spotify-playlists/GraphOne";
import Two from "../../../../src/articles/self-updating-spotify-playlists/Two";
import Three from "../../../../src/articles/self-updating-spotify-playlists/Three";
import SpotifySearchForFirstSong from "../../../../src/articles/self-updating-spotify-playlists/SpotifySearchForFirstSong";
import SpotifyAddTrackToPlaylist from "../../../../src/articles/self-updating-spotify-playlists/SpotifyAddTrackToPlaylist";
import SpotifyLambda from "../../../../src/articles/self-updating-spotify-playlists/SpotifyLambda";
import SpotifySearchForSong from "../../../../src/articles/self-updating-spotify-playlists/SpotifySearchForSong";
import YoutubeLambdaSQS from "../../../../src/articles/self-updating-spotify-playlists/YoutubeLambdaSQS";
import * as React from 'react';
export default {
  graphql,
  ArticleMeta,
  SEO,
  One,
  Two,
  Three,
  SpotifySearchForFirstSong,
  SpotifyAddTrackToPlaylist,
  SpotifyLambda,
  SpotifySearchForSong,
  YoutubeLambdaSQS,
  React
};