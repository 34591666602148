import React from 'react';
import Code from '../../common/Code';

function Two() {
  const code = `
import { DateTime } from 'luxon';
import { google } from 'googleapis';

const yt = google.youtube({
    version: 'v3',
    auth:  process.env.YT_API_KEY
});

// This list would include every channel IDs I wanted to check
const channels = [];

const getNewUploadsTitles = async (): Promise<string[]> => {
    const yesterday = DateTime.utc().minus({days: 1, hours: 1}).toISO();

    // For every channel
    const songs = await Promise.all(channels.map(async channel => {
        // get the new uploads since yesterday
        const channelUploads = await yt.search.list({
            "channelId": channel.ChannelId,
            "publishedAfter": yesterday,
            "part": "snippet",
            "fields": 'items(snippet/title)'
        });

        if (!channelUploads.data.items) {
            return;
        }

        // and only keep the titles of each uploads
        const songTitles = channelUploads.data.items
                           .filter(item => !!item?.snippet?.title)
                           .map(item => item.snippet.title);

        return songTitles;
    })).flat();
}
`;

  return <Code code={code} />;
}

export default Two;
