import React from 'react';
import Code from '../../common/Code';

function SpotifyAddTrackToPlaylist() {
  const code = `
async function addTrackToPlaylist(track: Spotify.SearchResponse) {
    await spotifyClient.addTracksToPlaylist(spotifyPlaylistId, [track.uri]);
}
  `;

  return <Code code={code} />;
}

export default SpotifyAddTrackToPlaylist;
