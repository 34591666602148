import React from 'react';
import Code from '../../common/Code';

function SpotifyLambda() {
  const code = `
...

export const handler = async (event: any = {}) => {
    // Extract content of AWS SQS message
    const query = event.Records[0].body;
    
    ...
};
  `;

  return <Code code={code} />;
}

export default SpotifyLambda;
