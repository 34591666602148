import React from "react"
import styled from "styled-components"
import { ArticleDate } from "./ArticleDate"
import { TimeToRead } from "./TimeToRead"

const Root = styled.span`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 30px;
  
  & > * {
    margin-left: 0 !important;
    margin-bottom: 0 !important;
    margin-top: 0 !important;
  }

  & > *:first-child {
    margin-right: var(--space-tiny);
  }
`

const Dot = styled.span`
  color: var(--subtext);
  margin-right: 3px;
`;


type Props = {
  timestamp: string
  date: string
  timeToRead: string
}

export function ArticleMeta({timestamp, date, timeToRead}: Props) {
  return (
    <Root>
    <ArticleDate timestamp={timestamp} date={date} />
    <Dot>·</Dot>
    <TimeToRead text={timeToRead} />
  </Root>
  )
}
