import React from 'react';
import Code from '../../common/Code';

function SpotifySearchForFirstSong() {
  const code = `
import Spotify from "spotify-web-api-node";

const spotifyClient = new Spotify({accessToken: process.env.SPOTIFY_ACCESS_TOKEN});

async function getFirstSongForQuery(query: string) {
    const searchResults = await spotifyClient.searchTracks(query);
    const tracks = searchResults?.body?.tracks;

    if (!tracks || tracks.items.length === 0) {
        throw new Error(\`No results: \${filteredSongQuery}\`);
    }

    return tracks.items[0];
}
  `;

  return <Code code={code} />;
}

export default SpotifySearchForFirstSong;
