import React from 'react';
import Code from '../../common/Code';

function One() {
  const code = `
const channelUploads = await yt.search.list({ // Search for videos
  "channelId": channel.ChannelId,             // for a specific channel
  "publishedAfter": yesterday,                // after timestamp (ISO)
  "part": "snippet",                          // select only basic info
  "fields": 'items(snippet/title)'            // filter results to keep only the title
})`;

  return <Code code={code} />;
}

export default One;
