import React, { useEffect } from 'react';

type Props = {
  code: string;
  language?: string;
};

function Code({ code, language }: Props) {
  useEffect(() => {
    const deckdeckgoLoader = require('@deckdeckgo/highlight-code/dist/loader');

    deckdeckgoLoader.defineCustomElements(window);
  }, []);

  return (
    <deckgo-highlight-code language={language}>
      <code slot="code">{code}</code>
    </deckgo-highlight-code>
  );
}

Code.defaultProps = {
  language: 'typescript',
} as Partial<Props>;

export default Code;
