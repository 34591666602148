import React from 'react';
import Code from '../../common/Code';

function Three() {
  const code = `
// AWS' Cron syntax is not standard and includes additional fields
   0 11 * * ? *
// ^        ^
// |        | -  Wildcard for any day of the week
// | - - - - - - Everyday at 11:00 AM UTC-0
  `;

  return <Code code={code} />;
}

export default Three;
