import React from "react"
import styled from "styled-components"

const Em = styled.em`
  color: var(--subtext);
  font-family: var(--font-title);
`

type Props = {
  text: string
}

export function TimeToRead({text}: Props) {
  return <Em>{text}</Em>
}
