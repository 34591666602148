import React from 'react';
import Code from '../../common/Code';

function SpotifyLambda() {
  const code = `
import Spotify from "spotify-web-api-node";

async function getFirstSongForQuery(query, spotifyClient) {
    const searchResults = await spotifyClient.searchTracks(query);
    const tracks = searchResults?.body?.tracks;

    if (!tracks || tracks.items.length === 0) {
        throw new Error(\`No results: \${filteredSongQuery}\`);
    }

    return tracks.items[0];
}

async function addTrackToPlaylist(track, spotifyClient) {
    await spotifyClient.addTracksToPlaylist(spotifyPlaylistId, [track.uri]);
}

export const searchAndAddToPlaylist = async (query: string) => {
    const spotifyClient = new Spotify({
      accessToken: process.env.SPOTIFY_ACCESS_TOKEN,
    });

    const spotifySearchResult = await searchSpotifyForSong(query, spotifyClient);

    await addTrackToPlaylist(spotifySearchResult, spotifyClient);
};
  `;

  return <Code code={code} />;
}

export default SpotifyLambda;
