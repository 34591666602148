import React from 'react';
import Code from '../../common/Code';

function Two() {
  const code = `
import he from 'he';
import flattenDeep from 'lodash.flattendeep';

const uuidv4 = require('uuid/v4');

...

export const handler = async (event: any = {}) => {
    const sqsMessages = await Promise.all(channels.map(async channel => {
        ...
        
        const sqsMessages = songTitles.map(message => ({
          Id: uuidv4(),
          MessageBody: he.decode(message),
          MessageGroupId: uuidv4()
        }));

        return songTitles;
    }));
    
    // Split messages into chunks of 10, the limit of messages per batch for SQS
    const chunks = chunk(flattenDeep(sqsMessages), 10);
    
    const sqs = new AWS.SQS({apiVersion: '2012-11-05', region : 'ca-central-1'});

    await Promise.all(chunks.map(chunk => {
        return sqs.sendMessageBatch({
            QueueUrl: process.env.AWS_SQS_QUEUE_URL,
            Entries: chunk
        }).promise();
    }));
}
`;

  return <Code code={code} />;
}

export default Two;
