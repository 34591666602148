import React from 'react';
import styled from 'styled-components';

const Root = styled.time`
  text-align: center;
  margin-top: 5px;
  margin-bottom: 20px;
  color: var(--subtext);
  font-family: var(--font-title);
`;

type Props = {
  timestamp: string;
  date: string;
};

export function ArticleDate({ timestamp, date }: Props) {
  return (
    <Root dateTime={timestamp}>
      <em>{date}</em>
    </Root>
  );
}
